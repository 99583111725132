import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { formatUSD } from '@shared/lib/numbers';
import { ContainerColumn, ContainerRow } from '@src/components/styled';
import { Body, BodyMediumSemiBold, BodySmall, } from '@src/components/styled/Typography';
import withPercentageIncomeBadge from '@shared/ui/badges/withPercentageIncomeBadge';
const Container = styled(ContainerColumn)((props) => ({
    width: 'auto',
    height: 'auto',
    gap: props.theme.spacing_sizes.m,
}));
const DetailsContainer = styled(ContainerColumn)((props) => ({
    width: 'auto',
    height: 'auto',
    minWidth: '200px',
    padding: props.theme.spacing_sizes.m,
    backgroundColor: '#FBFBFC',
    borderRadius: props.theme.shape.borderRadius,
    gap: props.theme.spacing_sizes.s,
}));
const BalanceChangeRow = styled(ContainerRow)((props) => ({
    height: 'auto',
    justifyContent: 'space-between',
    gap: props.theme.spacing_sizes.s,
}));
const BalanceChange = withPercentageIncomeBadge(BodySmall);
const BalanceChangeDetails = ({ wallets, activeWallet = undefined, }) => {
    const { t } = useTranslation();
    const { overallBalance, overallChangeCurrency, overallChangePercent, } = useMemo(() => wallets.reduce((acc, wallet) => {
        acc.overallBalance += Number(wallet.totalAssetsUsd) || 0;
        acc.overallChangeCurrency += wallet.balance_diff_in_currency;
        acc.overallChangePercent += wallet.balance_diff_in_percentage;
        return acc;
    }, {
        overallBalance: 0,
        overallChangeCurrency: 0,
        overallChangePercent: 0,
    }), [wallets]);
    const balance = activeWallet ? Number(activeWallet.totalAssetsUsd) : overallBalance;
    const balanceChange = activeWallet ?
        activeWallet.balance_diff_in_currency :
        overallChangeCurrency;
    const percentChange = activeWallet ?
        activeWallet.balance_diff_in_percentage :
        overallChangePercent;
    return (_jsxs(Container, { children: [_jsx(Body, { children: `${t('overview.balance')} / ${t('overview.full_day_change')}:` }), _jsxs(DetailsContainer, { children: [_jsx(BodyMediumSemiBold, { children: formatUSD(balance) }), wallets.length > 0 && (_jsxs(BalanceChangeRow, { children: [_jsx(BalanceChange, { percentageIncome: percentChange }), _jsx(BalanceChange, { percentageIncome: balanceChange, incomeSymbol: 'sign' })] }))] })] }));
};
export default BalanceChangeDetails;
